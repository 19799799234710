import { useContext, useState, useEffect } from 'react'
import { SlugContext } from '../../../contexts/SlugContext'
import Image from 'next/image'
import { imgApiUrl } from '../../../lib/constants'
import style from './Category.module.css'

interface ViewCategory {
	category_type: string
	categories: CategoryData[]
	familyBanner: familybanner
}

interface familybanner {
	image: string
	link: string
}

interface CategoryData {
	id: number
	name: string
	PoductTotal: number
}

const LeftColumn = (props: { view: ViewCategory }) => {
	const { selectedCategories, setSelectedCategories, selectedCategoriesP, setSelectedCategoriesP }: any = useContext(SlugContext)

	const familyClickFunc = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedCategories([...selectedCategories, id])
			setSelectedCategoriesP([...selectedCategoriesP, id])
		} else {
			setSelectedCategories((currentCategory: number[]) => currentCategory?.filter((catId: number) => catId !== id))
			setSelectedCategoriesP((currentCategory: number[]) => currentCategory?.filter((catId: number) => catId !== id))
		}
	}

	const [isOpen, setIsOpen] = useState(false)
	const [isMobileView, setIsMobileView] = useState(false)

	const toggleCollapse = () => {
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		const updateView = () => {
			setIsMobileView(window.innerWidth <= 1024)
		}
		updateView()
		window.addEventListener('resize', updateView)
		return () => window.removeEventListener('resize', updateView)
	}, [])

	return (
		<div className='category-left-column w-full lg:w-max flex flex-col justify-start items-start lg:items-start lg:justify-start'>
			{props?.view?.category_type === 'Shop_Categories' && props?.view?.categories?.some((category) => category?.PoductTotal > 0) && (
				<div className={`${style.familyCategory} family-wrapper bg-gray-100 p-3 w-max rounded-lg lg:mt-0 z-10 `}>
					<div className='family-dropdown-wrapper' onClick={toggleCollapse}>
						<div className={`family-title font-semibold cursor-pointer flex justify-between items-center ${isMobileView ? '' : 'pointer-events-none'}`}>
							<span>Family</span>
							{isMobileView && (
								<svg className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path d='M6 9L12 15L18 9' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
								</svg>
							)}
						</div>
						<div className={`transition-max-height duration-500 ease-in-out ${isOpen || !isMobileView ? 'h-full' : 'max-h-0 overflow-hidden'}`}>
							{props?.view?.categories?.map((family, index) => {
								if (family?.PoductTotal > 0) {
									return (
										<label key={index} className='relative text-sm mt-3 w-full cursor-pointer family-label flex items-center'>
											<input type='checkbox' className='mr-2 cursor-pointer' onChange={(e) => familyClickFunc(family?.id, e)} />
											<span className={`${style.wordWrapFamily}`}>{family?.name}</span>
											<span className='family-quantity'>({family?.PoductTotal})</span>
										</label>
									)
								}
								return null
							})}
						</div>
					</div>
				</div>
			)}

			<div className={`left-column-banner mt-6 rounded-lg ${style.detailImg}`}>
				<a href={props?.view?.familyBanner?.link}>
					<Image layout='fixed' width={'255px'} height={'400px'} lazyBoundary='400px' priority={true} className='left-column-banner mt-6 rounded-lg' src={`${imgApiUrl?.homeSliderBanner}/${props?.view?.familyBanner?.image}`} alt='Mini Bird Break' />
				</a>
			</div>
		</div>
	)
}

export default LeftColumn
