import React, { useContext, useEffect, useRef, useState } from 'react'
import { BASE_URL, imgApiUrl } from '../../lib/constants'
import { MainContext } from '../../contexts/MainContext'
import ProductCategory from '../Home/Products/ProductCategory'
import RightSection from '../Home/Products/RightSection'
import Image from 'next/dist/client/image'
import styles from './Product.module.css'
import { MessageContext } from '../../contexts/MessageContext'
import Magnifier from 'react-magnifier'
import MetaTags from '../MetaTags/MetaTags'
import Style from '../Product/ReviewsSection.module.css'
import { useRouter } from 'next/router'

const Detail = (props: any) => {
	const index = props?.images?.findIndex((img: any) => img?.default === 'Y')
	const [activeIndex, setActiveIndex] = useState<number>(index)
	const [activeIndex1, setActiveIndex1] = useState<number>(index)
	const [activeIndex2, setActiveIndex2] = useState<number>(index)
	const [activeIndex3, setActiveIndex3] = useState<number>(index)
	const defimg = props?.images?.filter((img: any) => img?.default == 'Y')
	const [defaultImg, setdefaultImg] = useState(props?.images?.length > 1 ? defimg[0]?.name : props?.images[0]?.name)

	const image = props?.images?.length > 0 ? `${imgApiUrl?.products?.large}/${defaultImg}`.replace('large', '') : props?.product?.image != null ? `${imgApiUrl?.products?.large}/${props?.product?.image}`.replace('large', '') : `${BASE_URL}/img/image.jpg`
	const [selectedImage, setSelectedImage] = useState(props?.images?.length > 0 ? `${imgApiUrl?.products?.large}/${defaultImg}` : `${imgApiUrl?.products?.large}/${props?.product?.image}`)
	const imageschema = props?.images?.length > 0 ? props?.images?.map((img: any) => `${imgApiUrl?.products?.large}/${img?.name}`) : [`${imgApiUrl?.products?.large}/${props?.product?.image}`]

	const router = useRouter()

	const range = props.product.range

	const magnifierRef = useRef(null)
	const [Qty, setQty] = useState(1)

	const { addToCart, totalCartItems, setTotalCartItems, setIsLoading } = useContext(MainContext)
	const { setAlert } = useContext(MessageContext)
	const [showitems, setShowItems] = useState('N')

	const changeQty = (qtyValue: any) => {
		if (qtyValue < 1) {
			qtyValue = 1
		}
		setQty(Number(qtyValue))
	}

	// Extracting prices from props
	const catalogPrice = props?.product?.price_catalog
	const discountedPrice = props?.product?.price_discounted

	// Calculating the discount percentage
	const discountPercentage = ((catalogPrice - discountedPrice) / catalogPrice) * 100

	const changeSelectedImage = (imageUrl: string) => {
		setSelectedImage(imageUrl)
	}

	const decrementQty = () => {
		if (Qty > 1) {
			setQty(Qty - 1)
		}
	}

	const addCart = async (product: any, qty: number) => {
		await addToCart(product, qty)
		await setTotalCartItems(totalCartItems + Number(qty))

		await setAlert({
			type: 'info',
			display: true,
			message: 'Item added in cart'
		})

		cartOpenBtnFunc()
	}

	const showImageFunc = async () => {
		document.body.classList.add('body-height')

		let enlargedImgContainer = document.querySelector('.enlarged-img-container'),
			enlargedImgWrapper: any = document.querySelector('.enlarged-img-wrapper')
		const gsap = (await import('gsap')).default
		let enlargeImgTl = gsap?.timeline()
		enlargeImgTl
			.set(enlargedImgContainer, { autoAlpha: 1 })
			.fromTo(
				enlargedImgContainer,
				{
					xPercent: -100
				},
				{
					xPercent: 0,
					ease: 'expo.inOut'
				}
			)
			.from(
				enlargedImgWrapper,
				{
					scale: 0.75,
					autoAlpha: 0,
					ease: 'back(2)'
				},
				'<90%'
			)
			.from(
				enlargedImgWrapper.querySelectorAll('.product-img-gallery div'),
				{
					scale: 0.5,
					autoAlpha: 0,
					ease: 'back(2)',
					stagger: 0.1
				},
				'<90%'
			)
	}

	const hideImageFunc = (e: any) => {
		let enlargedImgContainer = document.querySelector('.enlarged-img-container'),
			closeEnlargedImgContainer = document.querySelectorAll('.enlarged-img-container, .enlarged-close-btn, .enlarged-close-btn path')
		const gsapDetail = async () => {
			const gsap = (await import('gsap')).default
			gsap?.to(enlargedImgContainer, {
				autoAlpha: 0,
				duration: 0.3,
				onComplete: () => document.body.classList.remove('body-height')
			})
		}
		closeEnlargedImgContainer.forEach((btn) => {
			if (e.target === btn) {
				gsapDetail()
			}
		})
	}

	const replacingImageGalllary = (e: any) => {
		let productImg = document.querySelectorAll('.enlarged-img-wrapper .popup-img img, .product-img-wrapper img')

		let clickedImgSrc = e.target.getAttribute('src')
		let clickedImgSrcSet = e.target.getAttribute('srcset')
		changeSelectedImage(clickedImgSrc)
		productImg.forEach((img: any) => {
			img.src = clickedImgSrc
			img.srcset = clickedImgSrcSet
		})
	}

	const cartOpenBtnFunc = async () => {
		let cartContainer = document.querySelector('.cart-container'),
			cartWrapper = document.querySelector('.cart-container-wrapper')
		const gsap = (await import('gsap')).default
		document.body.classList.add('body-height')
		let cartTl = gsap?.timeline()
		cartTl
			.set(cartContainer, { autoAlpha: 1 })
			.fromTo(
				cartContainer,
				{
					xPercent: 100
				},
				{
					xPercent: 0,
					ease: 'expo.in'
				}
			)
			.fromTo(
				cartWrapper,
				{
					xPercent: 100
				},
				{
					xPercent: 0,
					ease: 'expo.out'
				},
				'<0.5'
			)
			.fromTo(
				'.alert-show',
				{
					xPercent: -50,
					autoAlpha: 0
				},
				{
					xPercent: 0,
					ease: 'back(2)',
					autoAlpha: 1,
					onComplete: () => {
						gsap.to('.alert-show', {
							xPercent: -50,
							autoAlpha: 0,
							duration: 0.3,
							delay: 2
						})
					}
				},
				'<90%'
			)
	}
	const gsapDetailEf = async () => {
		const gsap = (await import('gsap')).default
		gsap?.set('.enlarged-img-container', { autoAlpha: 0 })
	}
	useEffect(() => {
		gsapDetailEf()
	}, [])

	useEffect(() => {
		setIsLoading(false)

		setQty(1)
	}, [setIsLoading])

	const [selectedItems, setSelectedItems] = useState<{
		[key: string]: boolean
	}>({})

	const [totalPrice, setTotalPrice] = useState(props?.product?.price_catalog ? props?.product?.price_catalog : props?.sub_products?.price)

	const [sku, setSku] = useState(props?.product?.sku ? props?.product?.sku : props?.sub_products[0]?.sku)

	const [stock, setStock] = useState(props?.product?.in_stock ? props?.product?.in_stock : props?.sub_products?.in_stock)

	const [productname, setProductname] = useState(props?.product?.name ? props?.product?.name : props?.sub_products?.name)

	const [description, setDescription] = useState(props?.product?.short_description ? props?.product?.short_description : props?.sub_products?.short_description)

	const isAnyItemSelected = Object.values(selectedItems).some((isSelected) => isSelected)

	const [selectedItemImage, setSelectedItemImage] = useState(image)
	const [skuFromUrl, setSkuFromUrl] = useState(null)
	const [showItem, setShowItem] = useState(false)
	const formatImageUrl = (url) => {
		return url.replace('large', '')
	}

	const handleCheckboxChange = (itemName: string, isChecked: boolean, index: number) => {
		if (isChecked) {
			// Deselect all other checkboxes
			const updatedSelectedItems: { [key: string]: boolean } = {}
			props?.sub_products?.forEach((item: any) => {
				updatedSelectedItems[item?.name] = item?.name === itemName
			})
			setSelectedItems(updatedSelectedItems)

			// Update total price to the selected item's price
			const selectedItem = props?.sub_products?.find((item: any) => item?.name === itemName)
			if (selectedItem) {
				setQty(1) // Reset quantity to 1 when selecting a new item
				setTotalPrice(selectedItem?.price)
				// Set SKU to the selected item's SKU
				setSku(selectedItem?.sku)
				setStock(selectedItem?.in_stock)
				setProductname(selectedItem?.name)
				setDescription(selectedItem?.short_description)

				const imageUrl = formatImageUrl(`${imgApiUrl?.products?.large}/${selectedItem?.image}`)
				const imageSrcSet = `${imgApiUrl?.products?.large}/${selectedItem?.image}` // Assuming you have srcset logic
				setSelectedItemImage(`${imgApiUrl?.products?.large}/${selectedItem?.image}`.replace('large', ''))
				// Show or hide set items based on whether it's a surgical set
				setShowItem(selectedItem?.is_surgical_set === 'Y')

				setSelectedItemImage(imageUrl)
				setSelectedImage(imageUrl) // Ensure both image states are updated

				setActiveIndex2(index)
				setActiveIndex3(index)

				// Call the image replacing function with appropriate event object
				const fakeEvent = {
					target: {
						getAttribute: (attr: string) => (attr === 'src' ? imageUrl : imageSrcSet)
					}
				}
				replacingImageGalllary(fakeEvent)
			}
		} else {
			// If the checkbox is being deselected, just update its state
			setSelectedItems((prevState) => ({
				...prevState,
				[itemName]: isChecked
			}))
		}
	}

	const setInitialSelectedItem = (sku: string) => {
		const matchedItemIndex = props?.sub_products?.findIndex((item) => item?.sku === sku)
		if (matchedItemIndex !== -1) {
			const matchedItem = props?.sub_products[matchedItemIndex]
			handleCheckboxChange(matchedItem?.name, true, matchedItemIndex)
		}
	}

	useEffect(() => {
		// Extract SKU from the URL hash
		const hash = window?.location?.hash?.substring(1)
		if (hash) {
			setSkuFromUrl(hash)
		}
	}, [])

	useEffect(() => {
		// Set the initial selected item based on SKU from URL
		if (skuFromUrl) {
			setInitialSelectedItem(skuFromUrl)
		}
	}, [skuFromUrl])

	const addSelectedItemsToCart = async () => {
		for (const itemName in selectedItems) {
			if (selectedItems[itemName]) {
				const selectedItem = props?.sub_products?.find((item: any) => item?.name === itemName)
				if (selectedItem) {
					await addToCart(selectedItem, Qty)
					await setTotalCartItems((prevCount) => prevCount + Qty)
				}
			}
		}
		await setAlert({
			type: 'info',
			display: true,
			message: 'Items added to cart'
		})
		cartOpenBtnFunc()
	}

	useEffect(() => {
		setActiveIndex2(props?.sub_products?.length - 1)
	}, [props?.sub_products])
	useEffect(() => {
		setActiveIndex3(props?.sub_products?.length - 1)
	}, [props?.sub_products])

	const handleImageClick = (index: number) => {
		setActiveIndex(index)
		setActiveIndex1(index)
	}
	const handleImageClick1 = (index: number) => {
		setActiveIndex1(index)
		setActiveIndex(index)
	}
	const handleImageClick2 = (index: number) => {
		setActiveIndex2(index)
		setActiveIndex3(index)
	}

	const handleImageClick3 = (index: number) => {
		setActiveIndex3(index)
		setActiveIndex2(index)
	}

	const formatRange = (range: string) => {
		let [min, max] = range.split(' - ')

		if (min.endsWith('.00')) {
			min = min.slice(0, -3)
		}
		if (max.endsWith('.00')) {
			max = max.slice(0, -3)
		}

		return `${min} - ${max}`
	}

	return (
		<>
			<MetaTags
				status={props?.product?.status}
				do_index={props?.product?.do_index}
				ogImg={`${imgApiUrl.products.large.replace('large', '')}${props?.product?.image}`}
				title={props?.product?.meta_title}
				description={props?.product?.meta_description}
				keywords={props?.product?.meta_keywords}
				isCanonical={props?.product?.is_canonical}
				canonicalUrl={props?.product?.canonical_url}
			>
				<script
					type='application/ld+json'
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'Product',
							name: `${props?.product?.name}`,
							image: `${imageschema}`,
							description: `${props?.product?.meta_description}`,
							brand: {
								'@type': 'Brand',
								name: 'GerDentUSA Inc.'
							},
							sku: `${props?.product?.sku}`,
							offers:
								props.product.type === 'variation'
									? {
											'@type': 'AggregateOffer',
											url: `https://www.gerdentusa.com${router?.asPath}`,
											priceCurrency: 'USD',
											lowPrice: `${props?.product?.price_range_min}`,
											highPrice: `${props?.product?.price_range_max}`,
											offerCount: `${props?.sub_products?.length}`
									  }
									: {
											'@type': 'Offer',
											url: `https://www.gerdentusa.com${router?.asPath}`,
											priceCurrency: 'USD',
											price: `${props?.product?.price}`,
											availability: `${props?.product?.in_stock === 'Y' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}`,
											itemCondition: 'https://schema.org/NewCondition'
									  }
						})
					}}
				/>
			</MetaTags>
			<section className='product-page-product-wrapper width flex flex-col lg:flex-row space-between w-full'>
				<div className='flex flex-col md:flex-row w-full space-between items-center md:items-start'>
					<div className={`${styles.img_container} product-page-left-col w-full flex flex-col items-center col-span-1 overflow-hidden`}>
						<div className='product-img-container cursor-pointer w-full'>
							<div className=''>
								{props?.product?.price_discounted != '' && props?.product?.price_discounted != 0 && props?.product?.price_discounted != null ? (
									<>
										<div className='sale-notify-product absolute top-0 left-30 uppercase text-xs dark-blue-bg w-max text-white  px-2 py-1 tracking-widest z-10'> {Math.trunc(discountPercentage)}% Off</div>
									</>
								) : (
									''
								)}
							</div>

							<div className='product-img-wrapper w-full h-full relative overflow-hidden' onClick={() => showImageFunc()}>
								<div className='absolute top-0 left-0 p-6 w-full h-full'>
									<Image priority={true} width={600} height={600} src={selectedItemImage} alt={props?.product?.name} blurDataURL={image} placeholder='blur' className='d-main-img' />
								</div>
							</div>
							<div className='enlarge-img-wrapper flex justify-center items-center cursor-pointer text-gray-600' onClick={() => showImageFunc()}>
								<svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 mr-2' fill='none' viewBox='0 0 24 24' stroke='#151515'>
									<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
								</svg>
								<p className='text-sm'>click to see the large image</p>
							</div>
							{props?.images?.length > 1 && (
								<div className='product-img-gallery mt-4'>
									<div className='product-img-gallary-wrapper grid grid-cols-5 gap-2'>
										{props?.images?.map((img: any, index: any) => {
											return (
												<div key={index} className={`opacity-60 border border-solid rounded-lg border-gray-300 sm-img ${styles.activeHover} ${index === activeIndex ? styles.proActiveImg : ''}`} onClick={() => handleImageClick(index)}>
													<Image className='rounded-lg' width={600} height={600} src={img?.name !== '' ? `${imgApiUrl?.products?.large}/${img?.name}`.replace('large', '') : `${BASE_URL}/img/image.jpg`} alt={props?.product?.name} onClick={(e) => replacingImageGalllary(e)} lazyBoundary='400px' />
												</div>
											)
										})}
									</div>
								</div>
							)}

							<div className='product-img-gallery mt-4'>
								<div className='product-img-gallary-wrapper grid grid-cols-5 gap-2'>
									{props?.sub_products?.map((img: any, index: any) => {
										return (
											<div key={index} className={`opacity-60 border rounded-lg border-solid border-gray-300 sm-img ${styles.activeHover} ${index === activeIndex2 ? styles.proActiveImg : ''}`} onClick={() => handleImageClick2(index)}>
												<Image className='rounded-lg' width={600} height={600} src={img?.image !== '' ? `${imgApiUrl?.products?.large}/${img?.image}`.replace('large', '') : `${BASE_URL}/img/image.jpg`} alt={props?.product?.name} onClick={(e) => replacingImageGalllary(e)} lazyBoundary='400px' />
											</div>
										)
									})}
								</div>
							</div>
						</div>
						<div className={`enlarged-img-container  fixed top-0 left-0 w-screen h-screen z-50 cursor-pointer overflow-hidden flex justify-center items-center`} onClick={(event) => hideImageFunc(event)}>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 enlarged-close-btn absolute top-5 right-5' fill='none' viewBox='0 0 24 24' stroke='#ffffff'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
							</svg>
							<div className={`enlarged-img-wrapper justify-center w-10/12 lg:w-7/12 bg-white flex flex-col md:flex-row m-8 p-4 rounded shadow-lg overflow-y-auto overflow-x-hidden ${styles.enlargedImg}`}>
								<div className={`h-auto w-full md:w-8/12 ${styles.popUpImg} popup-img`}>
									<Magnifier src={selectedImage} mgTouchOffsetX={0} mgTouchOffsetY={0} className=' aspect-auto' />
								</div>
								{props?.images && props?.images?.length > 1 ? (
									<div className='product-img-gallery mt-4 w-full md:w-4/12 ml-3'>
										<div className='gallery-product-title text-lg my-4 font-semibold'>{productname}</div>
										<div className='product-img-gallary-wrapper grid grid-cols-5 gap-2'>
											{props?.images?.map((img: any, index: any) => (
												<div key={index} className={`opacity-60 border rounded-lg border-solid border-gray-300 ${styles.activeHover} ${index === activeIndex ? styles.proActiveImg : ''}`} onClick={() => handleImageClick1(index)}>
													<Image className='rounded-lg' priority width={600} height={600} src={`${imgApiUrl?.products?.large}/${img?.name}`} alt={props?.product?.name} onClick={(e) => replacingImageGalllary(e)} />
												</div>
											))}
										</div>
									</div>
								) : (
									<div className='product-img-gallery mt-4 w-full md:w-4/12 ml-3'>
										<div className='gallery-product-title text-lg my-4 font-semibold'>{productname}</div>
										<div className='product-img-gallary-wrapper grid grid-cols-5 gap-2'>
											{props?.sub_products?.map((img: any, index: any) => (
												<div key={index} className={`opacity-60 border rounded-lg border-solid border-gray-300 ${styles.activeHover} ${index === activeIndex3 ? styles.proActiveImg : ''}`} onClick={() => handleImageClick3(index)}>
													<Image className='rounded-lg' priority width={600} height={600} src={`${imgApiUrl?.products?.large}/${img.image}`} alt={props?.product?.name} onClick={(e) => replacingImageGalllary(e)} />
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className={`${styles.product_detail} product-page-center-col flex flex-col mt-10 md:mt-0 md:col-span-2 lg:col-span-3 w-full`}>
						<h1 className='product-title text-2xl font-bold leading-normal'>{productname}</h1>
						{/* <div className="product-sku font-semibold mt-6">
              <ReactStars  count={5}  size={22} color1={"#FFCC00"} />
            </div> */}
						<div className='product-sku font-semibold mt-6 flex'>
							<span> SKU:</span>
							<span className=' ml-2 dark-blue-color'>{sku}</span>
						</div>
						<div className='availability-wrapper mt-6 font-semibold'>
							Availability: <span className='stock ml-2 dark-blue-color'>{stock === 'Y' ? 'In Stock' : 'Out of Stock'}</span>
						</div>
						<div
							className={`${Style.description} product-description text-gray-600 mt-6 leading-normal`}
							dangerouslySetInnerHTML={{
								__html: description
							}}
						></div>

						{props?.product?.is_surgical_set === 'Y' && (
							<div className='mt-2 font-semibold w-full'>
								<div className='payment-detail-container w-full'>
									{props?.product?.set_items?.length > 0 && <div className='product-description mr-3 text-gray-600 leading-normal'>What's Included in This Set :</div>}
									<div className='payment-detail-wrapper m-2 text-sm sm:text-base'>
										{props?.product?.set_items?.map((item: any, index: number) => (
											<div key={index} className={`flex category-product-detail-table relative border-t gap-3 sm:gap-3 border-solid border-gray-300  items-start sm:items-center p-3 w-full text-sm cursor-pointer  ${index % 2 === 0 ? 'bg-gray-100 text-white' : 'bg-white '}`}>
												<div className='category-product-img absolute top-0 left-0 z-10 shadow-lg rounded-lg w-3/12'>
													<Image layout='responsive' width={198} height={198} className='rounded-lg' src={`${imgApiUrl?.products?.large}/${item?.image}`.replace('large', '')} lazyBoundary='400px' />
												</div>
												<div className=' w-[30px]  text-gray-600'>{item?.quantity}</div>
												<div className='w-[130px]  text-gray-600'>{item?.sku}</div>
												<div className='w-full sm:w-max-[200px] text-sm flex justify-start   text-gray-600'>{item?.name}</div>
											</div>
										))}
									</div>
								</div>
							</div>
						)}

						{showitems === 'Y' && (
							<div className='mt-2 font-semibold w-full'>
								<div className='payment-detail-container w-full'>
									{props?.sub_products?.length > 0 && <div className='product-description mr-3 text-gray-600  leading-normal'>What's Included in This Set :</div>}
									<div className='payment-detail-wrapper m-2 text-sm sm:text-base'>
										{props?.sub_products?.map((item: any, index: number) =>
											item?.set_items?.map((items: any, itemIndex: number) => (
												<div
													key={`${index}-${itemIndex}`}
													className={`flex category-product-detail    relative border-t gap-3 sm:gap-3 border-solid border-gray-300  items-start sm:items-center p-3 w-full text-sm cursor-pointer  ${(index + itemIndex) % 2 === 0 ? 'bg-white' : 'bg-gray-100 text-white'}`}
												>
													<div className='category-product-img absolute  top-0 left-0 z-10 shadow-lg rounded-lg w-3/12'>
														<Image layout='responsive' width={198} height={198} className='rounded-lg' src={`${imgApiUrl?.products?.large}/${items?.image}`.replace('large', '')} lazyBoundary='400px' />
													</div>
													<div className=' w-[30px] price   text-gray-600'>{items?.quantity}</div>
													<div className='w-[130px] price   text-gray-600'>{items?.sku}</div>
													<div className='w-full price sm:w-max-[200px] text-sm flex justify-start    text-gray-600'>{items?.name}</div>
												</div>
											))
										)}
									</div>
								</div>
							</div>
						)}
						<div className='border-gray-300 border-solid border-t mt-5'></div>

						{props?.sub_products?.length > 0 ? <h5 className='product-description mr-3 text-gray-600 font-bold mt-8 leading-normal'>Please select the variation you want to order</h5> : ''}
						{props?.sub_products?.map((item: any, index: number) => (
							<div key={index}>
								<label className='flex items-center gap-2 mt-3'>
									<input type='radio' name='selectedItem' className='product-description  text-gray-600  leading-normal' checked={selectedItems[item?.name] || false} onChange={(e) => handleCheckboxChange(item?.name, e.target.checked, index)} onClick={() => setShowItems(item?.is_surgical_set)} />

									<span className='product-description  text-gray-600  leading-normal cursor-pointer'> {item?.name}</span>
								</label>
							</div>
						))}

						{props?.product?.price_discounted != '' && props?.product?.price_discounted != 0 && props?.product?.price_discounted != null ? (
							<>
								<div className='flex'>
									&nbsp;&nbsp;
									<div className='font-bold line-through old-price text-xl text-black-400 mt-8 p-1'>${props?.product?.price_catalog}</div>
									<div className=' dark-blue-color font-bold mt-2 pt-6 text-2xl'>${props?.product?.price_discounted}</div>
								</div>
							</>
						) : totalPrice ? (
							<div className=' dark-blue-color font-bold mt-2 pt-6 text-2xl'>${totalPrice}</div>
						) : (
							<div className=' dark-blue-color font-bold mt-2 pt-6 text-2xl'>{props?.product?.price_range_min === props?.product?.price_range_max ? `$${props?.product?.price_range_max}` : formatRange(props?.product?.range)}</div>
						)}
						<div className='product-quantity-wrapper mt-6 flex items-center'>
							<div className='mr-6'>Quantity</div>
							<button className='dark-blue-bg text-white py-2 px-2 font-bold sm:mr-6 relative' onClick={() => decrementQty()}>
								-
							</button>
							&nbsp;&nbsp;
							<input
								type='text'
								min={1}
								maxLength={6}
								aria-label='Quantity'
								className='p-2 w-2/12 border-gray-300 border-solid border rounded mr-6'
								defaultValue={Qty}
								value={Qty}
								onChange={(e) => changeQty(e.target.value)}
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault()
									}
								}}
							/>
							<button className='dark-blue-bg text-white py-2 px-2 font-bold sm:mr-6 relative' onClick={() => setQty((prevCount) => prevCount + 1)}>
								+
							</button>
						</div>
						<div className='button-wrapper flex flex-col sm:flex-row mt-6'>
							{props?.sub_products?.length > 0 ? (
								isAnyItemSelected && (
									<button
										className='add-cart-btn dark-blue-bg text-white py-4 px-8 rounded-full shadow-lg cursor-pointer w-max font-bold sm:mr-6 relative'
										onClick={() => {
											// addCart(props.product, Qty);
											addSelectedItemsToCart()
										}}
									>
										Add To Cart
									</button>
								)
							) : (
								<button
									className='add-cart-btn dark-blue-bg text-white py-4 px-8 rounded-full shadow-lg cursor-pointer w-max font-bold sm:mr-6 relative'
									onClick={() => {
										addCart(props?.product, Qty)
										// addSelectedItemsToCart();
									}}
								>
									Add To Cart
								</button>
							)}
						</div>
						<div className='category-name mt-6 text-sm '>
							<span className='mr-1'>Categories:</span>
							<span className=''>
								{props?.product_categories?.map((product_categorie: any, index: any) => (
									<ProductCategory key={index} product_categorie={product_categorie} isLast={index === props.product_categories.length - 1} />
								))}
							</span>
						</div>

						<div className='notice lite-blue-bg-color p-4 text-gray-600 rounded-lg text-sm mt-4'>
							<p className='leading-normal'>
								<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline' fill='none' viewBox='0 0 24 24' stroke='#333'>
									<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
								</svg>{' '}
								&nbsp; All instruments are for Human Dental use only, if looking for Veterinary Instruments, please visit &nbsp;
								<a className='border-b border-solid border-black primary-blue-color' href='https://www.gervetusa.com/'>
									GerVetUSA.com
								</a>
								, for Human Surgical Instruments please visit &nbsp;
								<a className='border-b border-solid border-black primary-blue-color' href='https://www.germedusa.com/'>
									GerMedUSA.com
								</a>
								.
							</p>
						</div>
					</div>
				</div>
				<RightSection />
			</section>
			{/* <Alerts display={alert.display} type={alert.type} message={alert.message} /> */}
		</>
	)
}

export default Detail
