import React, { useContext } from 'react'
import Link from 'next/link'
import { MainContext } from '../../../contexts/MainContext'

const ProductCategory = (props: any) => {
	const { setIsLoading } = useContext(MainContext)
	return (
		<>
			<Link href={'/' + props?.product_categorie?.slug}>
				<a onClick={() => setIsLoading(true)}>
					<span className='mr-2 mt-2 primary-blue-color cursor-pointer'>
						{props?.product_categorie?.name}
						{!props.isLast && ' ,'}
					</span>
				</a>
			</Link>
		</>
	)
}

export default ProductCategory
