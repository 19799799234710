import { NextPage } from 'next'
import CategoryPage from '../components/Slugs/IndexPage'
import { API_BASE_URL } from '../lib/constants'
import { SlugProvider } from '../contexts/SlugContext'
import PageNotFound from './404'
import ProductPage from '../components/Product/ProductPage'
import Details from '../components/Page/Details'

const category: NextPage = ({ slug, slugRecord }: any) => {
	return (
		<>
			<SlugProvider>
				{slugRecord?.error !== 404 && slugRecord?.message !== 'Record not found' ? (
					slugRecord?.page_type === 'index' ? (
						<Details page={slugRecord?.page} slug={slug} />
					) : slugRecord?.page_type === 'Product_Slug' ? (
						<ProductPage url={slugRecord?.slug} view={slugRecord?.view} />
					) : (
						slugRecord?.page_type === 'Category_Slug' && <CategoryPage slugRecord={slugRecord} url={slugRecord?.slug} />
					)
				) : (
					<PageNotFound />
				)}
			</SlugProvider>
		</>
	)
}

export async function getServerSideProps({ params }: any) {
	let slug: any = params?.category
	slug = slug.join('/')

	const res = await fetch(`${API_BASE_URL}${slug}`).then((response) => response?.json())

	console.log('res', res, 'slug', `${API_BASE_URL}${slug}`)

	if (res?.page_type === 'Redirect_Slug') {
		return {
			redirect: {
				destination: `/${res?.view}`,
				permanent: true
			}
		}
	}

	return {
		props: {
			key: slug,
			slug: params?.category,
			slugRecord: res
		}
	}
}

export default category
