import React from 'react'
import ProductCarousel from './ProductCarousel'
import styles from './Product.module.css'
import { SwiperSlide } from 'swiper/react'
import SwiperSlider from '../Swiper/SwiperSlider'
import { Autoplay, Navigation } from 'swiper/modules'
interface Products {
	id: number
	slug: string
	name: string
	image: string
	price_discounted: number
	price_catalog: number
}
interface dataRecomend {
	data: Products[]
}
interface ProductCarouselProps {
	recommended_products: dataRecomend
}
const ProductLike = (props: ProductCarouselProps): JSX.Element => {
	return (
		<>
			<section className='product-page-related-products-container width'>
				<div className='product-page-related-products-wrapper'>
					<div className='related-products-title text-2xl font-semibold'>Items You Might Like</div>
					<div className='related-products-carasoul w-full h-full mt-6'>
						<div className={`${styles.product}`}>
							<SwiperSlider
								navigation={true}
								modules={[Autoplay, Navigation]}
								spaceBetween={15}
								slidesPerView={1}
								slidesPerGroup={1}
								loop={true}
								speed={1500}
								autoplay={{ delay: 5000, disableOnInteraction: true }}
								pagination={false}
								className={'custSwiper'}
								breakpoints={{
									1440: {
										slidesPerView: 5,
										slidesPerGroup: 5
									},
									1024: {
										slidesPerView: 4,
										slidesPerGroup: 4
									},
									786: {
										slidesPerView: 3,
										slidesPerGroup: 3
									},
									575: {
										slidesPerView: 2,
										slidesPerGroup: 2
									}
								}}
							>
								{props?.recommended_products?.data?.map((recommended_products: Products) => (
									<SwiperSlide key={recommended_products?.id}>
										<ProductCarousel recommended_products={recommended_products} />
									</SwiperSlide>
								))}
							</SwiperSlider>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default ProductLike
