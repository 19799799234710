import Style from '../Product/ReviewsSection.module.css'
import { useState } from 'react'
import dynamic from 'next/dynamic'

const FaqsSection = dynamic(() => import('./FaqsSection'), { ssr: false })
const ReviewSection = dynamic(() => import('./ReviewSection'), { ssr: false })
const ShippingAndReturns = dynamic(() => import('./ShippingAndReturns'), { ssr: false })
const ProductVideo = dynamic(() => import('./ProductVideo'), { ssr: false })
const ReviewsSection = (props: any) => {
	const [Section, setSection] = useState(1)

	return (
		<>
			<section className='product-detail-reviews bg-white sm-width my-7 md:my-14'>
				<div className='product-detail-reviews-wrapper bg-gray-100 py-6 px-4 sm:px-14 rounded-lg'>
					<div className='product-detail-review-title flex flex-wrap w-full md:place-content-around text-lg sm:text-xl font-semibold border-b border-solid border-gray-300 pb-4'>
						<h2 className={`mr-4 pb-2 cursor-pointer description-btn ${Section == 1 && 'dark-blue-color'}`} onClick={() => setSection(1)}>
							Description
						</h2>
						<h2 className={`mr-4 pb-2 cursor-pointer reviews-btn ${Section == 2 && 'dark-blue-color'}`} onClick={() => setSection(2)}>
							Reviews
						</h2>

						<h2 className={`mr-4 pb-2 cursor-pointer shipping-btn ${Section == 3 && 'dark-blue-color'}`} onClick={() => setSection(3)}>
							Guides
						</h2>
						<h2 className={`mr-4 pb-2 cursor-pointer shipping-btn ${Section == 4 && 'dark-blue-color'}`} onClick={() => setSection(4)}>
							FAQS
						</h2>
						<h2 className={`pb-2 cursor-pointer shipping-btn ${Section == 5 && 'dark-blue-color'}`} onClick={() => setSection(5)}>
							Videos
						</h2>
					</div>

					{Section === 1 && (
						<div
							className={`${Style.productDetail} mt-8 `}
							dangerouslySetInnerHTML={{
								__html: props?.product?.full_description
							}}
						/>
					)}
					<ReviewSection productId={props?.product?.id} showSection={Section == 2 ? true : false} />
					<ShippingAndReturns filespdf={props?.files} showSection={Section == 3 ? true : false} />
					<FaqsSection productId={props?.product?.id} faqsSection={props?.faqs} showSection={Section == 4 ? true : false} />
					<ProductVideo productVideo={props?.videos} showSection={Section == 5 ? true : false} />
				</div>
			</section>
		</>
	)
}

export default ReviewsSection
