import React, { useContext } from 'react'
import Link from 'next/link'
import { MainContext } from '../contexts/MainContext'

interface Breadcrumbs {
	name: string
	link: string
}

interface Data {
	name: string
}

interface BreadCrumbProps {
	data?: Data
	breadcrumbs?: Breadcrumbs[]
}

const Breadcrumb = (props: BreadCrumbProps) => {
	const { setIsLoading } = useContext(MainContext)
	return (
		<div className='bg-gray-100'>
			<div className='breadcrumb py-4 text-black width leading-snug breadcrum-up'>
				<Link href='/'>
					<a onClick={() => setIsLoading(true)}>Home</a>
				</Link>
				{props?.data && props?.data?.name && (
					<>
						<span> / </span>
						<span>{props?.data?.name}</span>
					</>
				)}
				{props?.breadcrumbs &&
					props?.breadcrumbs?.map((breadcrumb: Breadcrumbs, index: number) => (
						<React.Fragment key={index}>
							<span> / </span>

							{index === props?.breadcrumbs?.length - 1 ? (
								<span>{breadcrumb?.name}</span>
							) : (
								<Link href={'/' + breadcrumb?.link}>
									<a onClick={() => setIsLoading(true)}>{breadcrumb?.name}</a>
								</Link>
							)}
						</React.Fragment>
					))}
			</div>
		</div>
	)
}

export default Breadcrumb
