import React from 'react'
import Detail from './Detail'
import ProductLike from './ProductLike'
import ReviewsSection from './ReviewsSection'
import { useEffect } from 'react'
import Breadcrumb from '../../UIComponents/Breadcrumb'
import { featuredPro } from '../Home/Products/ProductList'
import MetaTags from '../MetaTags/MetaTags'
import { imgApiUrl } from '../../lib/constants'

// export interface slugRecords {
//   view: viewData;
// }
export interface slugRecords {
	view: {
		breadcrumbs?: breadObj[]
		images?: Image[]
		product?: featuredPro
		product_categories?: ProductCategory[]
		recommended_products?: RecommendedProducts
		sub_products?: any[]
		videos: string
		faqs: any
		files: any
	}
	url: string[]
}
interface breadObj {
	name: string
	link: string
}
interface RecommendedProducts {
	data: recommendedObj[]
}

interface recommendedObj {
	id: number
	slug: string
	name: string
	image: string
	price_discounted: number
	price_catalog: number
}

interface ProductCategory {
	slug: string
	name: string
}
export interface Image {
	images: imageData[]
	name: string
}
interface imageData {
	created_at: string
	description: string
	id: number
	name: string
	source: string
	title: string | null
	type: string
	updated_at: string
}
const ProductPage = (props: slugRecords) => {
	useEffect(() => {}, [props])

	return (
		<>
			<Breadcrumb breadcrumbs={props?.view?.breadcrumbs} />
			<div className='Categoery-page relative'>
				<div className='product-page-container  md:mb-28 mt-2  md:mt-10'>
					<div className='product-page-wrapper'>
						{props?.view?.product != undefined ? <Detail product={props?.view?.product} product_categories={props?.view?.product_categories} images={props?.view?.images} sub_products={props?.view?.sub_products} /> : ''}

						{/* product details and reviews section */}
						{props?.view?.product != undefined ? <ReviewsSection product={props?.view?.product} videos={props?.view?.videos} faqs={props?.view?.faqs} files={props?.view?.files} /> : ''}

						{/* related products */}
						{props?.view?.recommended_products != undefined ? <ProductLike recommended_products={props?.view?.recommended_products} /> : ''}
					</div>
				</div>
			</div>
		</>
	)
}

export default ProductPage
