import { useEffect } from 'react'
import ProductPage from '../Product/ProductPage'
import { featuredPro } from '../Home/Products/ProductList'
// export interface productProp {
//   slug: string[];
//   view: slugRecord;
// }
export interface slugRecords {
	view: {
		breadcrumbs?: breadObj[]
		images?: imageData[]
		product?: featuredPro
		product_categories?: ProductCategory[]
		recommended_products?: RecommendedProducts
		sub_products?: any[]
	}
	slug: string[]
}
// export interface viewData {
//   breadcrumbs: breadObj[];
//   images: Image[];
//   product: featuredPro;
//   product_categories: ProductCategory[];
//   recommended_products: RecommendedProducts;
//   sub_products: any[];
// }
interface breadObj {
	name: string
	link: string
}
interface ProductCategory {
	slug: string
	name: string
}
interface RecommendedProducts {
	data: recommendedData[]
}
interface recommendedData {
	slug: string
	image: string
	name: string
}

// export interface Image {
//   images: imageData;
// }
interface imageData {
	created_at: string
	description: string
	id: number
	name: string
	source: string
	title: string | null
	type: string
	updated_at: string
}

const Product = (props: any) => {
	useEffect(() => {}, [props])

	return (
		<>
			<link rel='stylesheet' href='/assets/css/slick.css' />
			<link rel='stylesheet' href='/assets/css/slick-theme.css' />

			{/* main content for product page */}
			{props?.slug != undefined ? <ProductPage url={props?.slug} view={props?.view} /> : ''}
		</>
	)
}

export default Product
