import React, { useEffect } from 'react'
import Image from 'next/dist/client/image'
import shippingOptionImg from '../../../public/assets/img/shipping-options-img.png'
import paymentImg from '../../../public/assets/img/payment-img.png'
import deliveryVanSvg from '../../../public/assets/icons/delivery-van.svg'
import styles from './Product.module.css'

declare global {
	interface Window {
		$_LHC: {
			eventListener: {
				emitEvent: (eventName: string) => void
			}
		}
	}
}

export default function RightSection() {
	const infoBoxFunc = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box')
		infoWrapper.classList.remove('hidden')
		setTimeout(() => {
			infoWrapper.classList.add('opacity-1-trans')
			infoWrapper.classList.remove('opacity-0')
		}, 100)
	}

	const infoBoxCloseFunc = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box')
		infoWrapper.classList.add('opacity-0')
		infoWrapper.classList.remove('opacity-1-trans')
		setTimeout(() => {
			infoWrapper.classList.add('hidden')
		}, 200)
	}

	const infoBox2Func = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box2')
		infoWrapper.classList.remove('hidden')
		setTimeout(() => {
			infoWrapper.classList.add('opacity-1-trans')
			infoWrapper.classList.remove('opacity-0')
		}, 100)
	}

	const infoBox2CloseFunc = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box2')
		infoWrapper.classList.add('opacity-0')
		infoWrapper.classList.remove('opacity-1-trans')
		setTimeout(() => {
			infoWrapper.classList.add('hidden')
		}, 200)
	}

	const infoBox3Func = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box3')
		infoWrapper.classList.remove('hidden')
		setTimeout(() => {
			infoWrapper.classList.add('opacity-1-trans')
			infoWrapper.classList.remove('opacity-0')
		}, 100)
	}

	const infoBox3CloseFunc = () => {
		let infoWrapper: Element = document.querySelector('.info-box-wrapper .info-box3')
		infoWrapper.classList.add('opacity-0')
		infoWrapper.classList.remove('opacity-1-trans')
		setTimeout(() => {
			infoWrapper.classList.add('hidden')
		}, 200)
	}

	useEffect(() => {
		document.getElementById('chat_now_btn').addEventListener('click', function () {
			if (typeof window.$_LHC !== 'undefined' && typeof window.$_LHC.eventListener !== 'undefined') {
				window.$_LHC.eventListener.emitEvent('showWidget')
			} else {
			}
		})
	}, [])

	return (
		<div className={`${styles.chat_col} product-page-right-col mt-8 lg:mt-0 self-center lg:self-start`}>
			<div className='product-page-right-col-wrapper bg-gray-100 p-4 w-full rounded-lg h-full'>
				<div className='product-page-returns-policy mt-4 flex flex-col justify-center text-sm lg:w-full'>
					<div className='button-wrapper flex flex-col items-center w-full mt-3 mb-6'>
						<button className={`${styles.phoneBtn} num-btn rounded-full shadow-lg cursor-pointer w-max font-bold relative`}>
							<a className='py-2 px-4 inline-block' href='tel:+15165937100'>
								<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline' fill='none' viewBox='0 0 24 24' stroke='white'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z'
									/>
								</svg>
								(516)593-7100
							</a>
						</button>
						<button id='chat_now_btn' className={`${styles.chatBtn}  chat-btn bg-white dark-blue-color py-2 px-4 rounded-full shadow-lg cursor-pointer w-max font-bold relative mt-4`}>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline' fill='none' viewBox='0 0 24 24' stroke='white'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z' />
							</svg>
							Chat Now
						</button>
					</div>
					<div className='font-semibold w-full flex justify-between relative info-box-wrapper'>
						<span>Returns &amp; Warranty</span>
						<div onMouseOver={() => infoBoxFunc()} onMouseLeave={() => infoBoxCloseFunc()}>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 cursor-pointer md:h-5 md:w-5' viewBox='0 0 20 20' fill='#52A0F2'>
								<path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clipRule='evenodd' />
							</svg>
							<div className='info-box absolute top-1 right-5 bg-white p-4 rounded text-xs z-10 text-gray-600 leading-snug shadow-lg hidden opacity-0'>
								<div className='info-title font-semibold text-black'>Return &amp; Warranty</div>
								<div className='flex items-center mt-2'>
									<svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4 mr-1 inline' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
										<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6' />
									</svg>
									<span className='font-semibold text-black'>Easy Returns</span>
								</div>
								<p className='mt-2 pb-2 border-b border-solid border-gray-300'>If any of GerDentUSA's instruments malfunction or conks out, we will repair or replace it depending on the product's warranty.</p>
								<div className='info-warranty pt-2'>
									<div className='flex items-center'>
										<svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 mr-1' viewBox='0 0 20 20' fill='currentColor'>
											<path
												fillRule='evenodd'
												d='M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z'
												clipRule='evenodd'
											/>
										</svg>
										<div className='warranty-title text-black font-semibold'>Warranty Details</div>
									</div>
									<p className='mt-2'>The instruments manufactured by GerDentUSA have limited warranties:</p>
									<ul className='warranty-lists list-disc pl-3 sm:pl-6'>
										<li className='mt-2'>Tungsten Carbide Scissors &gt; 5 years</li>
										<li className='mt-2'>Tungsten Carbide Needle Holder &gt; 1 year</li>
										<li className='mt-2'>Diamond Dust Instruments &gt; 1 year</li>
										<li className='mt-2'>Titanium Instruments &gt; 3 years</li>
										<li className='mt-2'> Silver or Chrome Plated Instruments &gt; 1 year</li>
										<li className='mt-2'>Super-cut Scissors &gt; 1 year</li>
										<li className='mt-2'>German stainless instruments &gt; 5 years</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className='mt-4'>
						<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline mr-1' fill='none' viewBox='0 0 24 24' stroke='#52A0F2'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
						German Made
					</div>
					<div className='mt-4'>
						<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline mr-1' fill='none' viewBox='0 0 24 24' stroke='#52A0F2'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
						Free Repair
					</div>
					<div className='product-page-warranty mt-4'>
						<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline mr-1' fill='none' viewBox='0 0 24 24' stroke='#52A0F2'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z' />
						</svg>
						5 Years Warranty
					</div>
					<div className='product-page-delivery'>
						<div className='mt-6 font-semibold flex justify-between w-full relative info-box-wrapper'>
							<span>Delivery</span>
							<div onMouseOver={() => infoBox2Func()} onMouseLeave={() => infoBox2CloseFunc()}>
								<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 cursor-pointer md:h-5 md:w-5' viewBox='0 0 20 20' fill='#52A0F2'>
									<path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clipRule='evenodd' />
								</svg>
								<div className='info-box2 absolute top-1 right-5 bg-white p-4 rounded text-xs z-10 text-gray-600 leading-snug shadow-lg hidden opacity-0'>
									<div className='info-title font-semibold text-black'>Shipping Options</div>
									<div className='flex items-center'>
										<div className='w-8 h-8 -ml-2 popup-img'>
											<Image src={deliveryVanSvg} alt='Worldwide Delivery' />
										</div>
										<span className='font-semibold text-black'>Worldwide Shipping</span>
									</div>
									<p>We ship via UPS and USPS. Any order that exceeds $200 automatically qualifies for free shipping (US only), excluding Puerto Rico, Hawaii &amp; Alaska.</p>
								</div>
							</div>
						</div>
						<div className='mt-4'>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 inline mr-1' fill='none' viewBox='0 0 24 24' stroke='#52A0F2'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
							</svg>
							Free Delivery Canada/ USA
						</div>
						<div className='mt-2'>
							<Image src={shippingOptionImg} alt='Shipping Options' />
						</div>
					</div>
					<div className='product-page-payments mt-6 flex flex-col justify-center text-sm'>
						<div className='font-semibold w-full flex justify-between relative info-box-wrapper'>
							<span>Payments</span>
							<div onMouseOver={() => infoBox3Func()} onMouseLeave={() => infoBox3CloseFunc()}>
								<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 cursor-pointer md:h-5 md:w-5' viewBox='0 0 20 20' fill='#52A0F2'>
									<path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clipRule='evenodd' />
								</svg>
								<div className='info-box3 absolute top-1 right-5 bg-white p-4 rounded text-xs z-10 text-gray-600 leading-snug shadow-lg hidden opacity-0'>
									<div className='info-title font-semibold text-black'>Payment Options</div>
									<div className='flex items-center mt-2'>
										<svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 mr-1' viewBox='0 0 20 20' fill='currentColor'>
											<path
												fillRule='evenodd'
												d='M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
												clipRule='evenodd'
											/>
										</svg>
										<span className='font-semibold text-black'>Secure and Reliable</span>
									</div>
									<p className='mt-2'>Your credit card is processed securely through our own credit card processing service. Each transaction is verified against name, address, and CVV number. Your card is removed from our system right after processing the transaction. </p>
								</div>
							</div>
						</div>
						<div className='mt-2 product-paymentImg'>
							<Image src={paymentImg} alt='Payment Options' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
