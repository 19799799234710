import React, { Fragment, useContext } from 'react'
import Link from 'next/link'
import { parse } from 'node-html-parser'
import { MainContext } from '../contexts/MainContext'

const BreadcrumbList = (props: any) => {
	const { setIsLoading } = useContext(MainContext)
	return (
		<>
			<div className='bg-gray-100'>
				<div className='breadcrumb py-4 text-black width leading-snug'>
					<Link href='/'>
						<a onClick={() => setIsLoading(true)}>Home</a>
					</Link>
					<span> / </span>

					{props?.breadcrumbs?.map((breadcrumb: any, index: any) => {
						let doc: any = parse(breadcrumb)
						let hrefAttr = doc.firstChild.rawAttributes != undefined ? doc.firstChild.rawAttributes.href : null
						return (
							<Fragment key={index}>
								{breadcrumb?.link ? (
									<Link href={breadcrumb?.link}>
										<a onClick={() => setIsLoading(true)}>{breadcrumb?.name}</a>
									</Link>
								) : (
									<span>{breadcrumb?.name}</span>
								)}
								{index < props?.breadcrumbs?.length - 1 && <span> / </span>}
							</Fragment>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default BreadcrumbList
