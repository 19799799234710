import { useContext, useEffect } from 'react'
import { MainContext } from '../../contexts/MainContext'
import LeftColumn from './category/LeftColumn'
import RightColumn from './category/RightColumn'
import Product from './Product'
import BreadcrumbList from '../../UIComponents/BreadcrumbList'
import MetaTags from '../MetaTags/MetaTags'
import { useRouter } from 'next/router'
import style from './category/Category.module.css'

const IndexSlugPage = (props: any) => {
	const { setIsLoading } = useContext(MainContext)
	const router = useRouter()

	useEffect(() => {
		setIsLoading(false)
	}, [props, setIsLoading])

	return (
		<>
			{props?.slugRecord != undefined && props?.slugRecord?.page_type == 'Category_Slug' && (
				<>
					<MetaTags
						status={props?.slugRecord?.view?.category?.status}
						do_index={props?.slugRecord?.view?.category?.do_index}
						title={props?.slugRecord?.view?.category?.meta_title}
						description={props?.slugRecord?.view?.category?.meta_description}
						keywords={props?.slugRecord?.view?.category?.meta_keywords}
						isCanonical={props?.slugRecord?.view?.category?.is_canonical}
						canonicalUrl={props?.slugRecord?.view?.category?.canonical_url}
					>
						{/* {(props?.slugRecord?.view?.category?.status === 'N' || props?.slugRecord?.view?.category?.do_index === 'N') && <meta name='robots' content='noindex' />} */}
						<script
							type='application/ld+json'
							dangerouslySetInnerHTML={{
								__html: JSON.stringify({
									'@context': 'https://schema.org/',
									'@type': 'BreadcrumbList',
									itemListElement: [
										{
											'@type': 'ListItem',
											position: 1,
											name: 'Home',
											item: 'https://www.gerdentusa.com/'
										},
										...(props?.slugRecord?.view?.breadcrumbs || [])?.map((category: any, index: number) => ({
											'@type': 'ListItem',
											position: index + 2,
											name: category?.name,
											item: category?.link ? `https://www.gerdentusa.com/${category?.link}` : `https://www.gerdentusa.com${router?.asPath}`
										}))
									]
								})
							}}
						/>
					</MetaTags>
					<BreadcrumbList breadcrumbs={props?.slugRecord?.view?.breadcrumbs} />
					<div className='Categoery-page relative'>
						<div className={`category-product-container width mt-2 md:mt-10 mb-7 ${style.categoryDetailPage} md:mb-28 flex flex-col lg:flex-row order-last lg:order-first`}>
							{/* left column */}
							{props?.slugRecord?.view != undefined ? <LeftColumn key={0} view={props?.slugRecord?.view} /> : ''}

							{/* right column starts here */}
							{props?.slugRecord?.view != undefined ? <RightColumn key={1} view={props?.slugRecord?.view} /> : ''}
						</div>
					</div>
				</>
			)}
			{props?.slugRecord != undefined && props?.slugRecord?.page_type == 'Product_Slug' && <Product slug={props?.url} view={props?.slugRecord?.view} />}
		</>
	)
}

export default IndexSlugPage
