import { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../../contexts/MainContext'
import { SlugContext } from '../../../contexts/SlugContext'
import Product from '../../Home/Products/Product'
import CategoryBlock from './CategoryProducts/CategoryBlock'
import CategoryProductList from './CategoryProducts/CategoryProductList'
import styles from './Category.module.css'

const RightColumn = (props: any) => {
	const { selectedCategories, selectedCategoriesP }: any = useContext(SlugContext)
	const [showMoreInfo, setshowMoreInfo] = useState(false)

	const { setIsLoading } = useContext(MainContext)
	useEffect(() => {
		setIsLoading(false)
	}, [setIsLoading])

	return (
		<div className='category-right-column lg:ml-10 lg:w-10/12 order-first lg:order-last'>
			<h1 className={`category-products-title text-3xl font-bold ${styles.headingName}`}>{props?.view?.category?.name}</h1>
			<div>
				<div className='main-cate-text'>
					<div>
						<div
							className={`category-description ${styles.categoryText} my-4 !text-gray-600 leading-normal ${styles.catDesc}`}
							dangerouslySetInnerHTML={{
								__html: props?.view?.category?.description?.substring(0, showMoreInfo ? props?.view?.category?.description?.length : 500) + `${!showMoreInfo && props?.view?.category?.description?.length > 500 ? '...' : ''}`
							}}
						/>
					</div>
				</div>
			</div>

			<div className='buttonBack-wraper'>
				<div className={styles.buttonBck}>
					{!showMoreInfo && props?.view?.category?.description?.length > 500 && (
						<button className='dark-blue-bg text-white py-4 px-8 rounded-full shadow-lg cursor-pointer w-max font-bold' onClick={() => setshowMoreInfo(true)}>
							Read More
						</button>
					)}
				</div>
			</div>

			{props?.view?.category_type === 'Shop_Categories' && props?.view?.categories[0]?.products?.length > 0 ? (
				selectedCategories?.length !== 0 ? (
					<>
						{props?.view?.categories
							.filter((family_category: any) => selectedCategories?.find((e: any) => e === family_category?.id))
							.map((family_category) => {
								return <CategoryProductList key={family_category?.id} family_category={family_category} />
							})}
					</>
				) : (
					<>
						{props?.view.categories?.map((family_category: any) => {
							return <CategoryProductList key={family_category?.id} family_category={family_category} />
						})}
						{props?.view?.main_categories?.length > 0 && <h3 className='category-products-title text-2xl font-bold mt-5'>More in {props?.view?.category?.name}</h3>}
						<div className={` ${styles.featuredProductsImgsContainer} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5 w-full gap-4`}>
							{props?.view?.main_categories?.map((family_category: any) => {
								return <CategoryBlock key={family_category?.id} family_category={family_category} setIsLoading={setIsLoading} />
							})}
						</div>
					</>
				)
			) : (
				''
			)}

			{props?.view?.category_type == 'Shop_Categories' && (props?.view?.categories[0]?.products?.length == 0 || props?.view?.categories?.length == 0) ? (
				selectedCategoriesP?.length != 0 ? (
					<div className={` ${styles.featuredProductsImgsContainer} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 w-full gap-4`}>
						{props?.view?.categories
							?.filter((family_category: any) => selectedCategoriesP?.find((e: any) => e === family_category?.id))
							?.map((family_category: any) => {
								return <CategoryBlock key={family_category?.id} family_category={family_category} setIsLoading={setIsLoading} />
							})}
					</div>
				) : (
					<div>
						{props?.view?.categories?.map((family_category: any) => {
							return <CategoryProductList key={family_category?.id} family_category={family_category} />
						})}

						{props?.view?.main_categories?.length > 0 && <h3 className='category-products-title text-2xl font-bold mt-10'>More in {props?.view?.category?.name}</h3>}
						<div className={` ${styles.featuredProductsImgsContainer} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5 w-full gap-4`}>
							{props?.view?.main_categories?.map((family_category: any) => {
								return <CategoryBlock key={family_category?.id} family_category={family_category} setIsLoading={setIsLoading} />
							})}
						</div>
					</div>
				)
			) : (
				''
			)}

			{props?.view?.category_type == 'Shop_List' ? (
				<div>
					<p className='text-lg p-1 flex justify-end '>{props?.view?.products?.total} Results</p>
					<div className={` ${styles.categoryProductsListContainer} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-10 w-full gap-4`}>
						{props?.view?.products?.data?.map((product: any, index: any) => {
							return <Product key={index} product={product} />
						})}
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default RightColumn
